type Response<T> = { data: T & any };

class PulppoError extends Error {
    response: { data: any };
}

export const axios = {
    defaults: {
        headers: {
            common: {}
        }
    },
    post: <T extends any>(url, body = {}, params = { headers: {} }): Promise<Response<T>> => {
        const err = new PulppoError();
        const isFormData = typeof FormData !== 'undefined' && body instanceof FormData;
        Error.captureStackTrace?.(err, axios.post);
        const request: RequestInit & { url?: string } = {
            url,
            method: 'POST',
            // credentials: 'include',
            body: isFormData ? body : JSON.stringify(body),
            ...params,
            headers: {
                ...axios.defaults.headers.common,
                ...(params.headers || {})
            }
        };
        if (!isFormData) {
            request.headers['Content-Type'] = 'application/json';
        }
        return fetch(url, request)
            .then(async (r) => {
                if (r.type == 'error' || r.status >= 400) {
                    const json = await r
                        .clone()
                        .json()
                        .catch(() => null);
                    let data = json || (await r.text().catch(() => null));
                    err.message = `Request for POST ${url} failed with code ${r.status}: ${r.statusText}.\nResponse: ${
                        typeof data == 'object' ? JSON.stringify(data, null, 2) : `${data}`
                    }\nRequest: ${JSON.stringify(request, null, 2)}`;
                    err.response = { data };

                    throw err;
                }
                const response = await r.text();
                try {
                    return JSON.parse(response);
                } catch (err) {
                    return response;
                }
            })
            .then((response) => {
                return {
                    data: response
                };
            });
    },
    patch: (url, body = {}, params = { headers: {} }) => {
        const err = new PulppoError();
        Error.captureStackTrace?.(err, axios.patch);
        const request: RequestInit & { url?: string } = {
            url,

            method: 'PATCH',
            // credentials: 'include',
            body: body instanceof FormData ? body : JSON.stringify(body),
            ...params,
            headers: {
                'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
                ...axios.defaults.headers.common,
                ...(params.headers || {})
            }
        };
        return fetch(url, request)
            .then(async (r) => {
                if (r.type == 'error' || r.status >= 400) {
                    const json = await r
                        .clone()
                        .json()
                        .catch(() => null);
                    let data = json || (await r.text().catch(() => null));
                    err.message = `Request for PATCH ${url} failed with code ${r.status}: ${r.statusText}.\nResponse: ${
                        typeof data == 'object' ? JSON.stringify(data, null, 2) : `${data}`
                    }\nRequest: ${JSON.stringify(request, null, 2)}`;

                    err.response = { data };
                    throw err;
                }
                const response = await r.text();
                try {
                    return JSON.parse(response);
                } catch (err) {
                    return response;
                }
            })
            .then((response) => {
                return {
                    data: response
                };
            });
    },
    put: (url, body = {}, params = { headers: {} }) => {
        const err = new PulppoError();
        Error.captureStackTrace?.(err, axios.put);
        const request: RequestInit & { url?: string } = {
            url,

            method: 'PUT',
            // credentials: 'include',
            body: body instanceof FormData ? body : JSON.stringify(body),
            ...params,
            headers: {
                'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
                ...axios.defaults.headers.common,
                ...(params.headers || {})
            }
        };
        return fetch(url, request)
            .then(async (r) => {
                if (r.type == 'error' || r.status >= 400) {
                    let data = await r.text().catch(() => null);
                    err.message = `Request for PUT ${url} failed with code ${r.status}: ${r.statusText}.\nResponse: ${
                        typeof data == 'object' ? JSON.stringify(data, null, 2) : `${data}`
                    }\nRequest: ${JSON.stringify(request, null, 2)}`;

                    err.response = { data };
                    throw err;
                }
                const response = await r.text();
                try {
                    return JSON.parse(response);
                } catch (err) {
                    return response;
                }
            })
            .then((response) => {
                return {
                    data: response
                };
            });
    },
    delete: (url, params: { data?: any; headers?: any } = { headers: {} }) => {
        const err = new PulppoError();
        Error.captureStackTrace?.(err, axios.delete);

        const request: RequestInit & { url?: string } = {
            url,
            method: 'DELETE',
            // credentials: 'include',
            ...params,
            headers: {
                ...axios.defaults.headers.common,
                ...(params.headers || {})
            }
        };
        if (params.data) {
            request.body = JSON.stringify(params.data);
            request.url = `${request.url}${request.url?.includes('?') ? '&' : '?'}${new URLSearchParams(params.data)}`;
        }
        return fetch(request.url, request)
            .then(async (r) => {
                if (r.type == 'error' || r.status >= 400) {
                    const json = await r
                        .clone()
                        .json()
                        .catch(() => null);
                    let data = json || (await r.text().catch(() => null));
                    err.message = `Request for DELETE ${url} failed with code ${
                        r.status
                    }: ${r.statusText}.\nResponse: ${
                        typeof data == 'object' ? JSON.stringify(data, null, 2) : `${data}`
                    }\nRequest: ${JSON.stringify(request, null, 2)}`;
                    err.response = { data };

                    throw err;
                }
                const response = await r.text();
                try {
                    return JSON.parse(response);
                } catch (err) {
                    return response;
                }
            })
            .then((response) => {
                return {
                    data: response
                };
            });
    },
    get: <T extends any>(url, params: { timeout?: number; headers?: any } = { headers: {} }): Promise<Response<T>> => {
        const err = new PulppoError();
        Error.captureStackTrace?.(err, axios.get);
        const request: RequestInit & { url?: string } = {
            url,
            method: 'GET',
            // credentials: 'include',
            ...params,
            headers: {
                ...axios.defaults.headers.common,
                ...(params.headers || {})
            }
        };
        let id;
        if (params.timeout) {
            const controller = new AbortController();
            id = setTimeout(() => controller.abort(), params.timeout || 10000);
            request.signal = controller.signal;
        }
        return fetch(url, request)
            .then(async (r) => {
                if (r.type == 'error' || r.status >= 400) {
                    const json = await r
                        .clone()
                        .json()
                        .catch(() => null);
                    let data = json || (await r.text().catch(() => null));

                    err.message = `Request for GET ${url} failed with code ${r.status}: ${r.statusText}.\nResponse: ${
                        typeof data == 'object' ? JSON.stringify(data, null, 2) : `${data}`
                    }\nRequest: ${JSON.stringify(request, null, 2)}`;
                    err.response = { data };
                    throw err;
                }
                const response = await r.text();
                try {
                    return JSON.parse(response);
                } catch (err) {
                    return response;
                }
            })
            .then((response) => {
                return {
                    data: response
                };
            })
            .finally(() => {
                if (id) clearTimeout(id);
            });
    }
};

export default axios;

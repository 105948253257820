import React, { SVGProps } from 'react';

const MtsSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M44.7707 26.3098C45.6807 27.2198 46.1357 28.8123 46.1357 30.8598V39.9598H42.2682V30.8598C42.2682 29.7223 42.0407 28.8123 41.5857 28.3573C41.1307 27.9023 40.6757 27.6748 39.7657 27.6748C38.8557 27.6748 37.9457 28.1298 37.4907 28.8123C36.8082 29.4948 36.5807 30.4048 36.5807 31.7698V40.1873H32.7132V31.0873C32.7132 29.9498 32.4857 29.0398 32.2582 28.5848C32.0307 28.1298 31.3482 27.9023 30.4382 27.9023C29.5282 27.9023 28.6182 28.3573 28.1632 29.0398C27.4807 29.7223 27.2532 30.6323 27.2532 31.9973V40.4148H23.3857V25.1723H27.0257V27.2198C27.4807 26.3098 28.1632 25.8548 28.8457 25.3998C29.5282 24.9448 30.4382 24.7173 31.5757 24.7173C33.8507 24.7173 35.4432 25.6273 36.1257 27.4473C36.5807 26.5373 37.2632 25.8548 38.1732 25.3998C39.0832 24.9448 39.9932 24.7173 41.1307 24.7173C42.4957 24.7173 43.8607 25.1723 44.7707 26.3098Z"
            fill="black"
        />
        <path
            d="M57.0552 26.0822V27.9022H49.3202V26.3097L53.1877 21.9873C53.6427 21.5323 54.0977 21.0772 54.0977 20.6222C54.3252 20.1672 54.3252 19.7122 54.3252 19.2572C54.3252 18.8022 54.0977 18.3472 53.8702 18.1197C53.6427 17.8922 53.1877 17.6647 52.5052 17.6647C51.3677 17.6647 50.4577 18.1197 49.3202 18.8022L48.6377 17.2097C49.0927 16.7547 49.7752 16.5273 50.4577 16.2998C51.1402 16.0723 51.8227 15.8447 52.7327 15.8447C53.8702 15.8447 54.7802 16.0722 55.4627 16.7547C56.1452 17.4372 56.3727 18.1197 56.3727 19.0297C56.3727 19.7122 56.1452 20.3948 55.9177 20.8498C55.6902 21.5323 55.2352 21.9872 54.5527 22.8972L51.8227 25.8547H57.0552V26.0822Z"
            fill="black"
            stroke="black"
            strokeWidth="1.27377"
            strokeMiterlimit="10"
        />
        <path
            d="M5.63867 1.96777V54.0653"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8301 63.8477H66.6101"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.0525 4.69774L5.64 1.74023L2 4.69774"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8774 60.2075L12.9199 63.8475L15.8774 67.26"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M63.8809 67.26L66.6109 63.8475L63.8809 60.2075"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 52.7002L5.64 55.4302L9.0525 52.7002"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.6068 54.2924H62.5143C64.5618 54.2924 66.3818 52.4724 66.3818 50.4249V6.5174C66.3818 4.4699 64.5618 2.6499 62.5143 2.6499H18.6068C16.5593 2.6499 14.7393 4.4699 14.7393 6.5174V50.4249C14.7393 52.4724 16.5593 54.2924 18.6068 54.2924Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default MtsSvg;

import React, { SVGProps } from 'react';

const ArrowTopRightOnSquareSvg = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="69"
            viewBox="0 0 68 69"
            fill="none"
            {...props}
        >
            <path
                d="M33.6224 2.53613H5.86753C3.82003 2.53613 2 4.35613 2 6.40363V62.5961C2 64.6436 3.82003 66.4636 5.86753 66.4636H62.0599C64.1074 66.4636 65.9274 64.6436 65.9274 62.5961V34.3861"
                stroke="black"
                strokeWidth="3.4125"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M65.9283 2.76367L24.0684 44.3961"
                stroke="black"
                strokeWidth="3.4125"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M65.9278 19.3711V2.53613H49.3203"
                stroke="black"
                strokeWidth="3.4125"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowTopRightOnSquareSvg;

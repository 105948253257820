import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

export const analytics = Analytics({
    app: "pulppo-agent-frontend",
    plugins: [
        segmentPlugin({
            writeKey: "5LfuO1HpoPDCUINYh2qKL3NwQTp4BoQu",
        }),
    ],
});

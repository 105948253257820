import React, { SVGProps } from 'react';

const SortSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group">
            <g id="Group_2">
                <g id="Group_3">
                    <path
                        id="Vector"
                        d="M15.7803 3.21963L12.7803 0.219604C12.7801 0.219463 12.78 0.219393 12.7799 0.219252C12.7625 0.20192 12.7443 0.185502 12.7253 0.169928C12.7167 0.162791 12.7075 0.156604 12.6986 0.149924C12.6879 0.141943 12.6775 0.133682 12.6664 0.126264C12.6557 0.119127 12.6446 0.112939 12.6336 0.1064C12.6236 0.100389 12.6138 0.0940957 12.6034 0.088541C12.5923 0.0825645 12.5808 0.077502 12.5694 0.0721231C12.5586 0.0670254 12.548 0.0616465 12.5369 0.057041C12.5259 0.0524707 12.5145 0.0487441 12.5033 0.0447363C12.4914 0.0404824 12.4798 0.0359473 12.4677 0.032291C12.4564 0.0288809 12.445 0.0263848 12.4336 0.0235371C12.4213 0.0204082 12.4091 0.0169629 12.3965 0.0144668C12.3833 0.0118652 12.37 0.0102832 12.3568 0.00838477C12.3459 0.00683789 12.3351 0.00472852 12.324 0.00363867C12.2748 -0.00121289 12.2252 -0.00121289 12.1759 0.00363867C12.1649 0.00472852 12.1541 0.00680273 12.1431 0.00838477C12.1299 0.0102832 12.1166 0.0118301 12.1034 0.0144668C12.0908 0.0169629 12.0787 0.0204082 12.0663 0.0235371C12.0549 0.0263848 12.0435 0.0288809 12.0323 0.032291C12.0202 0.0359473 12.0085 0.0404824 11.9967 0.0447363C11.9854 0.0487793 11.9741 0.0524707 11.963 0.057041C11.9519 0.0616113 11.9413 0.0670254 11.9306 0.0721231C11.9192 0.077502 11.9077 0.0825645 11.8965 0.088541C11.8862 0.0940957 11.8763 0.100389 11.8663 0.1064C11.8553 0.112939 11.8442 0.119127 11.8336 0.126264C11.8225 0.133682 11.812 0.141943 11.8014 0.149924C11.7924 0.156604 11.7833 0.162791 11.7746 0.169928C11.7556 0.185502 11.7374 0.20192 11.72 0.219252C11.7199 0.219393 11.7198 0.219463 11.7197 0.219604L8.71967 3.21959C8.42678 3.51248 8.42678 3.98737 8.71967 4.28026C9.01255 4.57314 9.48744 4.57314 9.78033 4.28026L11.5 2.56059V11.2499C11.5 11.6642 11.8358 11.9999 12.25 11.9999C12.6642 11.9999 13 11.6642 13 11.2499V2.56062L14.7196 4.28029C15.0125 4.57318 15.4874 4.57318 15.7803 4.28029C16.0732 3.98737 16.0732 3.51251 15.7803 3.21963Z"
                        fill="black"
                    />
                    <path
                        id="Vector_2"
                        d="M6.71964 11.7196L4.99997 13.4393V4.74999C4.99997 4.33578 4.6642 4 4.24999 4C3.83577 4 3.5 4.33578 3.5 4.74999V13.4393L1.78033 11.7196C1.48744 11.4268 1.01255 11.4268 0.719665 11.7196C0.426778 12.0125 0.426778 12.4874 0.719665 12.7803L3.71965 15.7803C3.71979 15.7804 3.71993 15.7805 3.72008 15.7807C3.73741 15.798 3.75565 15.8144 3.7746 15.83C3.78329 15.8371 3.79246 15.8433 3.80139 15.85C3.81204 15.858 3.82249 15.8663 3.83356 15.8737C3.84425 15.8808 3.85532 15.887 3.86629 15.8935C3.87634 15.8995 3.88615 15.9058 3.89649 15.9114C3.90767 15.9173 3.91916 15.9224 3.93059 15.9278C3.94135 15.9329 3.95197 15.9383 3.96301 15.9428C3.97408 15.9474 3.9854 15.9511 3.99665 15.9551C4.0085 15.9594 4.02017 15.9639 4.03226 15.9676C4.04351 15.971 4.05494 15.9735 4.06633 15.9763C4.07867 15.9795 4.09087 15.9829 4.10345 15.9854C4.11664 15.988 4.12993 15.9895 4.14318 15.9914C4.15411 15.993 4.16491 15.9951 4.17598 15.9962C4.2252 16.001 4.27481 16.001 4.32402 15.9962C4.3351 15.9951 4.34586 15.993 4.35683 15.9914C4.37008 15.9895 4.3834 15.988 4.39655 15.9854C4.40914 15.9829 4.4213 15.9794 4.43364 15.9763C4.44503 15.9734 4.45646 15.9709 4.46771 15.9675C4.4798 15.9639 4.49151 15.9593 4.50332 15.9551C4.51457 15.9511 4.52589 15.9474 4.53697 15.9428C4.54801 15.9382 4.55859 15.9328 4.56934 15.9277C4.58077 15.9224 4.5923 15.9173 4.60348 15.9113C4.61378 15.9058 4.62359 15.8995 4.63361 15.8935C4.64458 15.887 4.65569 15.8807 4.66638 15.8736C4.67745 15.8662 4.68786 15.858 4.69847 15.85C4.70744 15.8433 4.71661 15.8371 4.72533 15.8299C4.74414 15.8145 4.76225 15.7982 4.77947 15.781C4.77976 15.7808 4.78004 15.7805 4.78028 15.7803L7.78027 12.7803C8.07316 12.4874 8.07316 12.0125 7.78027 11.7196C7.48738 11.4267 7.01256 11.4268 6.71964 11.7196Z"
                        fill="black"
                    />
                </g>
            </g>
        </g>
    </svg>
);
export default SortSvg;

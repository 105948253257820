import React, { SVGProps } from 'react';
const PriceSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M42.3155 25.1721C42.3155 25.1721 39.1305 20.8496 34.5805 20.8496C30.4855 20.8496 26.8455 22.8971 26.618 27.2196C26.3905 31.5421 30.4854 33.3621 35.0354 34.4996C39.1304 35.4096 42.543 37.9121 42.543 41.5521C42.543 45.4196 38.4479 47.6946 34.1254 47.6946C28.4379 47.6946 25.4805 43.5996 25.4805 43.5996"
            stroke="black"
            strokeWidth="3.49781"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.5801 20.8499V16.7549"
            stroke="black"
            strokeWidth="3.49781"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.5801 51.79V48.8325"
            stroke="black"
            strokeWidth="3.49781"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.125 3.7875C50.96 3.7875 64.8375 17.4375 64.8375 34.5C64.8375 51.335 51.1875 65.2125 34.125 65.2125C17.29 65.2125 3.4125 51.5625 3.4125 34.5C3.4125 17.665 17.29 3.7875 34.125 3.7875ZM34.125 0.375C15.2425 0.375 0 15.6175 0 34.5C0 53.3825 15.2425 68.625 34.125 68.625C53.0075 68.625 68.25 53.3825 68.25 34.5C68.25 15.6175 53.0075 0.375 34.125 0.375Z"
            fill="black"
        />
    </svg>
);
export default PriceSvg;

import { create } from 'zustand';
import { IContact, IInteraction, ISearch, ISimpleUser } from '@pulppo/pulppo-models';
import { ReactNode } from 'react';

interface IChat extends Omit<IContact, 'agent' | 'searches'> {
    asAgent?: ISimpleUser;
    preferredMethod?: string;
    lead?: Partial<IInteraction>;
    callCenter?: boolean;
    startCall?: string;
    message?: string;
    attachment?: { url: string; name: string; type: string; mimetype: string };
    searchId?: string;
    search?: ISearch;
    onClose?: Function;
    prepend?: {
        hideMessages: true;
        component: ReactNode;
    };
}
interface UseChatStore {
    chatContact?: IChat;
    setChatContact: (chat: IChat | Partial<IChat> | ((chat: IChat) => IChat)) => void;
}

export const useChat = create<UseChatStore>((set, store) => ({
    chatContact: null,
    setChatContact: async (chat) => {
        const oldChat = store().chatContact;
        if (!chat) {
            return set({ chatContact: null });
        }
        const newChat = typeof chat === 'function' ? chat(oldChat) : { ...oldChat, ...chat };
        set({ chatContact: newChat });
        return;
    }
}));

export default useChat;

import React, { SVGProps } from 'react';

const ArrowBottomLeftOnSquareSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="69"
        viewBox="0 0 68 69"
        fill="none"
        {...props}
    >
        <path
            d="M33.6225 2.6499H5.86753C3.82003 2.6499 2 4.4699 2 6.5174V62.7099C2 64.7574 3.82003 66.5774 5.86753 66.5774H62.06C64.1075 66.5774 65.9275 64.7574 65.9275 62.7099V34.4999"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.0684 44.2823L65.9283 2.42236"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.9033 44.2828H24.0684V27.6753"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ArrowBottomLeftOnSquareSvg;

import { useContext, useEffect, useState } from 'react';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { UserContext } from '../../contexts/UserContext';
import axios from '../../utils/axios';
import { wapp_url } from '../../helpers/fetcher';
import { PulppoLoader } from '../Elements/PulppoLoader';
import WhatsappIcon from '../Icon/WhatsappIcon';
import MenuIcon from '../Icon/MenuIcon';
import SettingsIcon from '../Icon/SettingsIcon';
import Alert from '../Closes/Alert';
import { LoadingButton } from '../Elements/ButtonLoading';
import useUser from '../../hooks/useUser';

const WhatsappInactive = ({ onClose }) => {
    const user = useUser((u) => u.user);
    const [show, setShow] = useState(true);
    const [qr, setQr] = useState(null);
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        if (!user?.uid) return;
        const interval = setInterval(async () => {
            const qr = await axios
                .get(`${wapp_url}/qr/${user?.uid}`, { timeout: 1000 })
                .then((r) => r?.data.qr)
                .catch(() => null);
            setQr(qr);

            const exists = await axios
                .get(`${wapp_url}/status/${user?.uid}`, {
                    timeout: 1000
                })
                .then((r) => r?.data.status === 'open')
                .catch(() => null);
            if (exists) {
                setUser((u) => ({ ...u, whatsapp: true }));
                onClose?.();
                clearInterval(interval);
            }
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [user?.uid]);

    return (
        <PulppoDrawer
            title="WhatsApp inactivo"
            visible={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}
        >
            <div className="md:hidden">
                <p className="mb-4">
                    Es importante mantener vinculado tu WhatsApp para atender con facilidad y rapidez a potenciales
                    clientes, y hacer el correcto seguimiento de tus clientes en sincronización con la plataforma
                </p>
                <Alert
                    type="warning"
                    content={[
                        {
                            text: 'Para vincular tu whatsapp debes ingrear a'
                        },
                        {
                            text: 'https://broker.pulppo.com',
                            emphasize: true
                        },
                        {
                            text: 'desde tu computadora'
                        }
                    ]}
                />
            </div>
            <div className="hidden h-full flex-col md:flex">
                <p>
                    Es importante mantener vinculado tu WhatsApp para atender con facilidad y rapidez a potenciales
                    clientes, y hacer el correcto seguimiento de tus clientes en sincronización con la plataforma
                </p>
                <div className="mb-10 mt-8">
                    <PulppoLoader loading={!qr} className="h-[300px]">
                        <img
                            alt="qr"
                            className="mx-auto"
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(qr)}`}
                        />
                    </PulppoLoader>
                </div>

                <p className="font-semibold">Vincula WhatsApp en tu computadora</p>
                <div className="mt-2 flex flex-col gap-2">
                    {[
                        <p key={1} className="flex">
                            1. Abre{' '}
                            <span className="mx-1">
                                <WhatsappIcon />
                            </span>{' '}
                            WhatsApp en tu teléfono principal.
                        </p>,
                        <div key={2}>
                            <p className="flex">
                                <span>2. Pulsa</span>
                                <span className="mx-1">
                                    <MenuIcon />
                                </span>{' '}
                                <span>Menú o</span>
                                <span className="mx-1">
                                    <SettingsIcon />
                                </span>{' '}
                                <span>Configuración y selecciona “Dispositivos</span>
                            </p>
                            <p>vinculados”</p>
                        </div>,

                        <p key={3} className="flex">
                            3. Toca “Vincular dispositivo”.
                        </p>,
                        <p key={1} className="flex">
                            4. Apunta tu teléfono hacia esta pantalla para poder escanear con tu cámara el código QR.
                        </p>
                    ].map((text, index) => (
                        <div key={index}>{text}</div>
                    ))}
                </div>
                <div className="flex flex-1 items-center justify-center">
                    <a
                        className="text-center font-semibold underline"
                        href="https://ayuda.pulppo.com/es/articles/6882186-whatsapp-recopilacion-de-datos"
                        target="_blank"
                        rel="noreferrer"
                    >
                        ¿Cómo utilizamos la información de tu WhatsApp?
                    </a>
                </div>
                <div>
                    <LoadingButton
                        type="primary"
                        className="h-11 w-full font-medium"
                        onClick={async () => {
                            await axios.delete(`${wapp_url}/${user?.uid}`);
                        }}
                    >
                        Borrar conexión y reintentar
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};

export { WhatsappInactive };

import dynamic from 'next/dynamic';
import { ComponentType } from 'react';
import { IImageProps } from './NextImageClient';

export const NextImage: ComponentType<IImageProps> = dynamic(
    () => import('./NextImageClient').then((c) => c.NextImageClient),
    {
        ssr: false
    }
);

import React, { SVGProps } from 'react';

const GarageSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M63.1982 60.4922H6.0957V47.2972C6.0957 45.2497 7.91573 43.4297 9.96323 43.4297H59.3307C61.3782 43.4297 63.1982 45.2497 63.1982 47.2972V60.4922Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M58.8755 43.4296H10.6455L17.4705 25.6846C18.153 24.0921 19.518 23.1821 21.1105 23.1821H48.638C50.2305 23.1821 51.5955 24.0921 52.278 25.6846L58.8755 43.4296Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.6932 66.6342H10.8732C8.37074 66.6342 6.32324 64.5867 6.32324 62.0842V60.4917H17.2432V62.0842C17.2432 64.5867 15.1957 66.6342 12.6932 66.6342Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M58.4198 66.6342H56.5998C54.0973 66.6342 52.0498 64.5867 52.0498 62.0842V60.4917H62.9698V62.0842C62.9698 64.5867 60.9223 66.6342 58.4198 66.6342Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M46.1357 52.0742H23.1582"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M55.9189 34.1021H61.834V38.6521"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.376 34.1025H7.68848V38.6525"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.8516 43.4295L43.634 33.647"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 17.722L33.395 2.70697C34.5325 2.25197 35.67 2.25197 36.8075 2.70697L67.2925 17.267"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default GarageSvg;

import { BulbOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface AlertProps {
    className?: string;
    type: 'danger' | 'warning' | 'info' | 'success';
    content: Array<{
        text: string;
        emphasize?: boolean;
        className?: string;
    }>;
    button?: ReactNode;
    icon?: {
        element: ReactNode;
        className?: string;
    };
}

const insightMapper = {
    info: {
        backgroundColor: 'bg-notification-blue',
        bulbColor: 'bg-pulppo-status-dark-info',
        color: 'text-pulppo-status-dark-info'
    },
    danger: {
        backgroundColor: 'bg-notification-red',
        bulbColor: 'bg-strong-red',
        color: 'text-strong-red'
    },
    warning: {
        backgroundColor: 'bg-notification-yellow',
        bulbColor: 'bg-strong-yellow',
        color: 'text-strong-yellow'
    }
};

export const Alert = ({ type, content, button, icon, className }: AlertProps) => {
    return (
        <div
            className={twMerge(
                'flex items-center justify-between gap-2 p-2',
                insightMapper[type].backgroundColor,
                className
            )}
        >
            <div className="flex items-center gap-2">
                <div
                    className={twMerge(
                        'flex w-8 shrink-0 items-center justify-center rounded-full py-2 text-white',
                        insightMapper[type].bulbColor,
                        icon?.className
                    )}
                >
                    {icon?.element || <BulbOutlined />}
                </div>
                <div className="flex items-center">
                    <p className="text-sm">
                        {content.map((part, i) => (
                            <span
                                key={`insight-${i}`}
                                className={twMerge(
                                    part.emphasize && `ml-1 mr-1 font-medium ${insightMapper[type].color}`,
                                    part.className
                                )}
                            >
                                {part.text}
                            </span>
                        ))}
                    </p>
                </div>
            </div>
            {button}
        </div>
    );
};

export default Alert;

import React, { SVGProps } from 'react';
const HourglassSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91"
        height="91"
        viewBox="0 0 91 91"
        fill="none"
        {...props}
    >
        <path
            d="M61.6986 70.1378H29.3254C24.6844 70.1378 20.9307 66.3613 20.9307 61.7431V33.8288C20.9307 29.1878 24.7072 25.4341 29.3254 25.4341H61.6986C66.3396 25.4341 70.0934 29.2106 70.0934 33.8288V61.7431C70.0934 66.3841 66.3169 70.1378 61.6986 70.1378ZM29.3254 28.8238C26.5727 28.8238 24.3432 31.0533 24.3432 33.8061V61.7204C24.3432 64.4731 26.5727 66.7026 29.3254 66.7026H61.6986C64.4514 66.7026 66.6809 64.4731 66.6809 61.7204V33.8061C66.6809 31.0533 64.4514 28.8238 61.6986 28.8238H29.3254Z"
            fill="black"
        />
        <path
            d="M35.3537 33.9427C34.421 33.9427 33.6475 33.1692 33.6475 32.2364V22.0217C33.6475 21.0889 34.421 20.3154 35.3537 20.3154C36.2865 20.3154 37.06 21.0889 37.06 22.0217V32.2364C37.06 33.1692 36.2865 33.9427 35.3537 33.9427Z"
            fill="black"
        />
        <path
            d="M55.6467 33.9427C54.7139 33.9427 53.9404 33.1692 53.9404 32.2364V22.0217C53.9404 21.0889 54.7139 20.3154 55.6467 20.3154C56.5794 20.3154 57.3529 21.0889 57.3529 22.0217V32.2364C57.3529 33.1692 56.5794 33.9427 55.6467 33.9427Z"
            fill="black"
        />
    </svg>
);
export default HourglassSvg;

import React, { SVGProps } from 'react';

const ToiletSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="69"
        viewBox="0 0 46 69"
        fill="none"
        {...props}
    >
        <path
            d="M37.2812 66.8049H9.07129L15.2138 50.8799H31.3663L37.2812 66.8049Z"
            stroke="black"
            strokeWidth="2.68131"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M35.9181 50.8798H10.4381C5.88809 50.8798 2.47559 47.2398 2.47559 42.9173V30.6323H44.1081V42.9173C44.1081 47.4673 40.4681 50.8798 35.9181 50.8798Z"
            stroke="black"
            strokeWidth="2.68131"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M41.6043 30.6323H4.74927L2.01924 6.51735C1.79174 4.24235 3.61177 2.19482 5.88677 2.19482H40.2394C42.5144 2.19482 44.3342 4.24235 44.1067 6.51735L41.6043 30.6323Z"
            stroke="black"
            strokeWidth="2.68131"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.92871 9.01953H43.8787"
            stroke="black"
            strokeWidth="2.68131"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.30078 15.1621H16.8083"
            stroke="black"
            strokeWidth="2.68131"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ToiletSvg;

import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { useRouter } from 'next/router';

interface Props {
    selectedTimezone: string;
    toRedirect: string;
}

const prevTimezone = {
    get: () => {
        return localStorage.getItem('timezone');
    },
    set: (timezone: string) => {
        localStorage.setItem('timezone', timezone);
    }
};

const useTimezone = ({ selectedTimezone, toRedirect }: Props) => {
    const router = useRouter();

    useEffect(() => {
        if (selectedTimezone && toRedirect) {
            dayjs.tz.setDefault(selectedTimezone);

            const currentTimezone = dayjs.tz.guess();
            if (
                currentTimezone !== selectedTimezone &&
                prevTimezone.get() !== currentTimezone
            ) {
                Modal.warn({
                    title: 'Cambio de zona horaria',
                    content:
                        'Se ha detectado un cambio en la zona horaria. Quieres modificarla ?',
                    onOk: async () => {
                        await router.push(toRedirect);
                    },
                    okCancel: true
                });
                prevTimezone.set(currentTimezone);
            }
        }
    }, [selectedTimezone, toRedirect]);
};

export default useTimezone;

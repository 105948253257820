import { IAgent, ISimpleUser } from '@pulppo/pulppo-models';
import { Button, Modal } from 'antd';
import { NextImage } from '../components/Elements/NextImage';
import { IdcardOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import axios from './axios';
import { api_url } from '../helpers/fetcher';
import { Root, createRoot } from 'react-dom/client';

const AgentModal = ({ onCancel, agent, openChat }) => {
    return (
        <Modal footer={null} open={true} onCancel={onCancel}>
            <div className="flex flex-col items-center gap-4">
                <div className="w-full">
                    <h2 className="mb-4 text-lg font-bold">Vista de usuario</h2>
                    <div className="h-1 w-full bg-primary-color"></div>
                </div>
                <div className="mt-6 h-32 w-32 shrink-0">
                    <NextImage
                        src={agent.profilePicture}
                        showPreview={true}
                        width={128}
                        height={128}
                        className="h-full w-full rounded-full object-cover"
                        alt={`${agent.firstName} ${agent?.lastName}`}
                    />
                </div>
                <h3 className="text-lg font-bold">
                    {agent?.firstName} {agent?.lastName}
                </h3>
                <div className="w:4/5 mx-auto flex flex-col gap-2 lg:w-2/3">
                    <div className="flex items-center gap-4">
                        <Button
                            onClick={async () => {
                                const contact = await axios
                                    .post(`${api_url}/contact`, {
                                        firstName: agent.firstName,
                                        lastName: agent?.lastName,
                                        profilePicture: agent?.profilePicture,
                                        phones: [
                                            {
                                                phone: `+${agent.phone}`,
                                                plain: agent.phone
                                            }
                                        ],
                                        emails: [{ email: agent.email }]
                                    })
                                    .then((r) => r.data);
                                openChat({ ...contact, agent: null });
                            }}
                            className="flex h-auto items-center justify-center rounded-md px-3 py-3"
                        >
                            <MailOutlined className="text-xl" />
                        </Button>{' '}
                        <div>
                            <p className="font-medium">Email</p>
                            <p className="font-light text-pulppo-status-dark-disabled">{agent?.email}</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <Button
                            onClick={async () => {
                                const contact = await axios
                                    .post(`${api_url}/contact`, {
                                        firstName: agent.firstName,
                                        lastName: agent?.lastName,
                                        profilePicture: agent?.profilePicture,
                                        phones: [
                                            {
                                                phone: `+${agent.phone}`,
                                                plain: agent.phone
                                            }
                                        ],
                                        emails: [{ email: agent.email }]
                                    })
                                    .then((r) => r.data);

                                openChat(contact);
                            }}
                            className="flex h-auto items-center justify-center rounded-md px-3 py-3"
                        >
                            <WhatsAppOutlined className="text-xl" />
                        </Button>{' '}
                        <div>
                            <p className="font-medium">Teléfono</p>
                            <p className="font-light text-pulppo-status-dark-disabled">{agent?.phone}</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <Button
                            onClick={async () => {
                                const contact = await axios
                                    .post(`${api_url}/contact`, {
                                        firstName: agent.firstName,
                                        lastName: agent?.lastName,
                                        profilePicture: agent?.profilePicture,
                                        phones: [
                                            {
                                                phone: `+${agent.phone}`,
                                                plain: agent.phone
                                            }
                                        ],
                                        emails: [{ email: agent.email }]
                                    })
                                    .then((r) => r.data);
                                openChat(contact);
                            }}
                            className="flex h-auto items-center justify-center rounded-md px-3 py-3"
                        >
                            <IdcardOutlined className="text-xl" />
                        </Button>{' '}
                        <div>
                            <p className="font-medium">Sucursal</p>
                            <p className="font-light text-pulppo-status-dark-disabled">{agent?.company?.name}</p>
                        </div>
                        <div className="h-12 w-12 shrink-0">
                            <NextImage
                                className="h-12 w-12 rounded-full border border-solid border-gray-100 object-contain p-1"
                                height={64}
                                width={64}
                                showPreview={false}
                                src={agent?.company?.logo?.default}
                                alt={agent?.company?.name}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const showAgentData = (initialAgent: IAgent | ISimpleUser, openChat: Function) => {
    if (!initialAgent) return;
    const container = document.getElementById('agent-modal');
    let root: Root = null;
    setTimeout(async () => {
        const agent: IAgent = await axios.get(`${api_url}/agent/public/${initialAgent.uid}`).then((r) => r.data);
        root = createRoot(container);
        root.render(
            <AgentModal
                openChat={openChat}
                onCancel={() => {
                    root.unmount();
                }}
                agent={agent}
            />
        );
    });
    return {
        destroy: () => {
            root?.unmount();
        }
    };
};

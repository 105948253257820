import React, { SVGProps } from 'react';

const BedSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="57"
        viewBox="0 0 68 57"
        fill="none"
        {...props}
    >
        <path
            d="M6.32324 54.5489V43.4014"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.377 54.5489V43.4014"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.605 43.401H6.09501C3.82001 43.401 2 41.581 2 39.306V28.1585C2 25.8835 3.82001 24.0635 6.09501 24.0635H61.8325C64.1075 24.0635 65.9275 25.8835 65.9275 28.1585V39.306C65.9275 41.3535 64.1075 43.401 61.605 43.401Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.3773 23.6087H6.5498V6.31867C6.5498 4.27117 8.36982 2.45117 10.4173 2.45117H57.5098C59.5573 2.45117 61.3773 4.27117 61.3773 6.31867V23.6087Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M57.0553 23.6088H37.7178V21.1063C37.7178 17.4663 40.6753 14.7363 44.0878 14.7363H50.4578C54.0978 14.7363 56.8278 17.6938 56.8278 21.1063V23.6088H57.0553Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M30.8932 23.6088H11.5557V21.1063C11.5557 17.4663 14.5132 14.7363 17.9257 14.7363H24.2957C27.9357 14.7363 30.6656 17.6938 30.6656 21.1063V23.6088H30.8932Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default BedSvg;

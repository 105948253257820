import React, { SVGProps } from 'react';

const MeasureSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="41"
        viewBox="0 0 68 41"
        fill="none"
        {...props}
    >
        <path
            d="M8.82422 7.76025H60.0117"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6451 4.34766L8.14258 7.76016L10.6451 11.4002"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M57.2812 11.4002L60.0112 7.76016L57.2812 4.34766"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 13.4473V2.2998"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.0996 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.2002 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.5264 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M38.627 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.7266 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.8262 30.2822V18.9072"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.9268 13.4473V2.2998"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M62.06 38.7003H5.86749C3.81999 38.7003 2 36.8802 2 34.8327V23.0027C2 20.9552 3.81999 19.1353 5.86749 19.1353H62.06C64.1075 19.1353 65.9275 20.9552 65.9275 23.0027V34.8327C65.9275 36.8802 64.335 38.7003 62.06 38.7003Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default MeasureSvg;

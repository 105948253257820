import {
    SettingOutlined,
    EllipsisOutlined,
    ArrowLeftOutlined,
    CaretRightOutlined,
    BellOutlined,
    MailOutlined,
    MessageOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { MailIcon, MailOpenIcon, TrashIcon } from '@heroicons/react/outline';
import { ButtonTypeEnum } from '@novu/node';
import { useNotifications, useFetchUserPreferences, useUpdateUserPreferences } from '@novu/notification-center';
import { Dropdown, Collapse, Switch } from 'antd';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import EmptyState from '../Elements/EmptyState';
import Button from '../Elements/Button';
import { LoadingButton } from '../Elements/ButtonLoading';

const NovuMapper = {
    sms: {
        label: 'Whatsapp',
        icon: <MessageOutlined />
    },
    in_app: {
        label: 'In App',
        icon: <BellOutlined />
    },
    email: {
        label: 'Email',
        icon: <MailOutlined />
    },
    push: {
        label: 'Push',
        icon: <PhoneOutlined />
    }
};

export const Notifications = () => {
    const result = useNotifications();
    const preferences = useFetchUserPreferences();
    const { updateUserPreferences } = useUpdateUserPreferences();

    const [tab, setTab] = useState('notification');
    const router = useRouter();

    return (
        <div className="h-full w-full flex-1 overflow-x-hidden">
            <>
                {tab === 'notification' ? (
                    <div className="flex h-full w-full flex-col">
                        <div className="flex justify-between p-2">
                            <h4 className="text-lg font-bold">Notificaciones</h4>
                            <div className="flex">
                                {result.unreadCount ? (
                                    <>
                                        <div
                                            onClick={() => result.markAllNotificationsAsRead()}
                                            className="cursor-pointer px-2 text-pulppo-status-dark-disabled"
                                        >
                                            Marcar todos como leídos
                                        </div>
                                    </>
                                ) : null}
                                <div className="cursor-pointer px-2" onClick={() => setTab('configuration')}>
                                    <SettingOutlined />
                                </div>{' '}
                            </div>
                        </div>
                        {result?.notifications?.length ? (
                            <div className="flex flex-1 flex-col gap-4 px-2 pb-4">
                                {result.notifications?.map((notification) => (
                                    <div
                                        key={notification._id}
                                        className={twMerge(
                                            'flex items-center justify-between p-4 transition-all',
                                            notification.read
                                                ? 'bg-gray-100 text-pulppo-primary-gray'
                                                : 'border-l-4 border-solid border-pulppo-primary-black bg-white font-bold shadow-lg'
                                        )}
                                    >
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                if (!notification.read) {
                                                    result.markNotificationAsRead(notification._id);
                                                }
                                                router.push(notification.cta?.data?.url);
                                            }}
                                        >
                                            <div className="flex gap-2">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: notification.content as string
                                                    }}
                                                />
                                            </div>
                                            {notification.cta?.action?.buttons?.length ? (
                                                <div className="my-2 flex w-full items-center gap-2">
                                                    {notification.cta?.action?.buttons?.map((button) => (
                                                        <LoadingButton
                                                            key={`${notification._id}_${button.content}`}
                                                            type={
                                                                button.type === ButtonTypeEnum.PRIMARY
                                                                    ? 'primary'
                                                                    : 'ghost'
                                                            }
                                                        >
                                                            {button.content}
                                                        </LoadingButton>
                                                    ))}
                                                </div>
                                            ) : null}
                                            <p className="text-xs font-thin text-pulppo-primary-gray">
                                                {dayjs().to(notification.createdAt)}
                                            </p>
                                        </div>
                                        <div>
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        notification.read && {
                                                            label: 'Marcar como no leído',
                                                            icon: <MailIcon className="h-4 w-4" />,
                                                            key: 'mark-unread',
                                                            onClick: () => {
                                                                result.markNotificationAsUnRead(notification._id);
                                                            }
                                                        },
                                                        !notification.read && {
                                                            label: 'Marcar como leído',
                                                            icon: <MailOpenIcon className="h-4 w-4" />,
                                                            key: 'mark-read',
                                                            onClick: () => {
                                                                result.markNotificationAsRead(notification._id);
                                                            }
                                                        },
                                                        {
                                                            label: 'Eliminar',
                                                            icon: <TrashIcon className="h-4 w-4" />,
                                                            key: 'delete',
                                                            onClick: () => {
                                                                result.removeMessage(notification._id);
                                                            }
                                                        }
                                                    ].filter(Boolean)
                                                }}
                                                trigger={['click']}
                                            >
                                                <div className="cursor-pointer p-2">
                                                    <EllipsisOutlined />
                                                </div>
                                            </Dropdown>
                                        </div>
                                    </div>
                                ))}
                                {result.hasNextPage && (
                                    <Button
                                        type="secondary"
                                        isLoading={result.isLoading}
                                        onClick={() => result.fetchNextPage()}
                                    >
                                        Cargar más
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <div className="flex w-full flex-col items-center justify-center p-8">
                                <EmptyState
                                    title="No hay notificaciones"
                                    description="Por ahora no tienes ninguna notificación, pronto empezarán a aparecer aquí."
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex h-full w-full flex-col">
                        <div className="flex items-center gap-2 p-2">
                            <div className="cursor-pointer p-2" onClick={() => setTab('notification')}>
                                <ArrowLeftOutlined />
                            </div>
                            <h4 className="text-lg font-bold">Configuración</h4>
                        </div>
                        <div className="notification-center flex flex-1 flex-col gap-4 px-2 pb-4">
                            <Collapse
                                className="flex flex-col gap-4"
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                {preferences.data
                                    ?.filter(
                                        (preference) =>
                                            preference?.preference &&
                                            preference.preference.enabled &&
                                            Object.keys(preference.preference.channels)?.length
                                    )
                                    ?.map((preference) => (
                                        <Collapse.Panel
                                            key={preference.template._id}
                                            header={
                                                <div className="w-full">
                                                    <h5>{preference.template.name}</h5>
                                                    <p className="text-xs font-thin capitalize text-pulppo-status-dark-disabled">
                                                        {Object.keys(preference.preference.channels)
                                                            .map((channel) => NovuMapper[channel].label)
                                                            .join(', ')}
                                                    </p>
                                                </div>
                                            }
                                            className="flex flex-col bg-white p-2 font-bold shadow-lg transition-all"
                                        >
                                            <div className="flex flex-col gap-4 border-t border-solid border-gray-100 pt-4">
                                                {Object.entries(preference.preference.channels).map((channel) => (
                                                    <div
                                                        key={`${preference.template._id}_${channel[0]}`}
                                                        className="flex items-center justify-between"
                                                    >
                                                        <label className="flex flex-1 gap-2 font-light capitalize">
                                                            {NovuMapper[channel[0]].icon}
                                                            {NovuMapper[channel[0]].label}
                                                        </label>
                                                        <Switch
                                                            checked={channel[1]}
                                                            onChange={(checked) => {
                                                                updateUserPreferences({
                                                                    templateId: preference.template._id,
                                                                    channelType: channel[0],
                                                                    checked: checked
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </Collapse.Panel>
                                    ))}
                            </Collapse>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

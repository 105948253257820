import { create } from 'zustand';
import { IUser as User } from '../contexts/UserContext';

type IUser = User & { showAll?: boolean };
interface UseUserStore {
    user?: IUser;
    setUser: (user: IUser | Partial<IUser> | ((chat: IUser) => IUser)) => void;
}

export const useUser = create<UseUserStore>((set, store) => ({
    user: null,
    setUser: async (user) => {
        const oldUser = store().user;
        if (!user) {
            return set({ user: null });
        }
        const newUser: IUser = typeof user === 'function' ? user(oldUser) : { ...oldUser, ...user };
        newUser.showAll = newUser?.features?.viewAll || newUser?.permissions?.stats?.view;
        set({ user: newUser });
        return;
    }
}));

export default useUser;

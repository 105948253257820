import { IAgent, ICompany, IContact, ISimpleCompany, ISimpleUser } from '@pulppo/pulppo-models';

export const MapUserToSimpleUser = (contact = {} as IContact | IAgent | ISimpleUser): ISimpleUser => ({
    _id: contact?._id || '',
    company: contact?.company?.name ? MapCompanyToSimpleCompany(contact?.company) : null,
    profilePicture: contact?.profilePicture || '',
    firstName: contact?.firstName || '',
    lastName: contact?.lastName || '',
    uid: (contact as IAgent)?.uid || '',
    phone: (contact as IContact)?.phones?.[0]?.phone || (contact as IAgent)?.phone || '',
    email: (contact as IContact)?.emails?.[0]?.email || (contact as IAgent)?.email || ''
});

export const MapCompanyToSimpleCompany = (company = {} as ICompany | ISimpleCompany): ISimpleCompany => ({
    _id: company?._id,
    name: company?.name || '',
    external: company?.external || false,
    email: company?.email || '',
    phone: company?.phone || '',
    logo: company?.logo || { default: '', pulppo: '', pulppoInverted: '' }
});

import { RcFile } from 'antd/lib/upload';
import { UploadMetadata, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { FirestoreApp } from './authentication';

function normalizeFileName(fileName) {
    return fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export class ImageUploaderService {
    static config(provider: string, config: any) {}

    static async upload(
        file: Buffer | RcFile | File,
        prefix: string,
        events = null,
        force: boolean = false,
        options = null as (UploadMetadata & { private?: boolean }) | null
    ): Promise<string> {
        const storage = getStorage(FirestoreApp);
        return new Promise((resolve, reject) => {
            let name =
                typeof Buffer !== 'undefined' && file instanceof Buffer
                    ? prefix
                    : force
                      ? prefix
                      : `${prefix}/${(file as File).name}`;
            if (options?.private) {
                name = `private/${name}`;
            }
            if (!storage) return '';
            const storageRef = ref(storage, normalizeFileName(name.replace(/ /gi, '_')));
            const task = uploadBytesResumable(storageRef, file, options);

            task.on(
                'state_changed',
                function progress(snapshot) {
                    if (events?.onProgress)
                        events.onProgress({
                            percent: Math.floor(snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        });
                },
                function error(err) {
                    if (events?.onError) events.onError(err);
                    return reject(err);
                },
                function complete() {
                    const fileUrl = options?.private
                        ? `private://${task.snapshot.ref.fullPath}`
                        : `${process.env.NEXT_PUBLIC_IMAGE_URL || `https://images.pulppo.com`}/${task.snapshot.ref.fullPath}`;
                    if (events?.onSuccess) events.onSuccess(fileUrl);
                    return resolve(fileUrl);
                }
            );
        });
    }
}

import { IAgent } from '@pulppo/pulppo-models';
import { ReactNode } from 'react';
import {
    AcademyIcon,
    ContactsIcon,
    HomeIcon,
    LegalsIcon,
    MarketingIcon,
    OperationsIcon,
    PortfolioIcon,
    ProductionsIcon,
    PropertiesIcon,
    SearchesIcon,
    StatsIcon,
    TasksIcon
} from './Icons';

interface IRoute {
    route: string;
    title: string;
    query?: (user: IAgent) => string;
    condition: (pathname: string) => boolean;
    disabled?: (user: IAgent) => boolean;
    icon: (active: boolean) => ReactNode;
}

const routes: Array<IRoute | Partial<IRoute>> = [
    {
        route: '/',
        title: 'home',
        condition: (pathname) => pathname == '/',
        icon: (active: boolean) => <HomeIcon active={active} />
    },

    {
        route: '/search',
        title: 'properties',
        condition: (pathname) =>
            pathname.includes('property') ||
            pathname.includes('listing') ||
            pathname === '/search' ||
            pathname.includes('properties'),
        icon: (active: boolean) => <PropertiesIcon active={active} />
    },
    {
        route: '/contact',
        title: 'contacts',
        condition: (pathname) => pathname.includes('contact'),
        icon: (active: boolean) => <ContactsIcon active={active} />
    },
    {
        title: 'divider'
    },
    {
        route: '/buyers',
        title: 'searches',
        condition: (pathname) => pathname == '/buyers' || pathname.includes('searches'),
        icon: (active: boolean) => <SearchesIcon active={active} />
    },
    {
        route: '/captaciones',
        title: 'captaciones',
        condition: (pathname) => pathname.includes('/captaciones'),
        icon: (active: boolean) => <ProductionsIcon active={active} />,
        disabled: (user: IAgent) => !user?.features?.capture10
    },
    {
        route: '/tasks',
        title: 'tasks',
        condition: (pathname) => pathname == '/tasks',
        icon: (active: boolean) => <TasksIcon active={active} />
    },
    {
        route: '/portfolio',
        title: 'Inventario',
        query: (user: IAgent) => new URLSearchParams({ agents: user?.uid as string }).toString(),
        condition: (pathname) => pathname.includes('/portfolio'),
        icon: (active: boolean) => <PortfolioIcon active={active} />
    },
    {
        route: '/operations',
        title: 'operations',
        condition: (pathname) => pathname.includes('/operations') || pathname.includes('/closes'),
        icon: (active: boolean) => <OperationsIcon active={active} />
    },
    {
        title: 'divider'
    },
    {
        route: '/marketing-center',
        condition: (pathname) => pathname.includes('marketing'),
        title: 'marketing_center',
        icon: (active: boolean) => <MarketingIcon active={active} />
    },

    {
        route: '/stats',
        title: 'stats',
        condition: (pathname) => pathname == '/stats',
        icon: (active: boolean) => <StatsIcon active={active} />
    },

    {
        route: '/academia',
        condition: (pathname) => pathname.includes('/academia'),
        title: 'academy',
        icon: (active: boolean) => <AcademyIcon active={active} />
    },
    {
        route: '/legal',
        title: 'legal',
        disabled: (user: IAgent) => !user?.features?.legal && !user?.permissions?.legal?.view,
        condition: (pathname) => pathname.includes('/legal'),
        icon: (active: boolean) => <LegalsIcon active={active} />
    }
].filter(Boolean);
const publicRoutes = [
    '/login',
    '/welcome/[step]',
    '/privacy',
    '/terms',
    '/providers',
    '/404',
    '/suspended',
    '/viewcma/[urlcode]',
    '/admin/[id]',
    '/admin/edit/[id]',
    '/workspace/[id]',
    '/radar/[id]',
    '/radar/[id]/legal',
    '/radar/[id]/acm',
    '/radar/[id]/stats',
    '/radar/[id]/login',
    '/searches/public/[id]',
    '/property/public/[id]',
    '/operations/public/[id]',
    '/property/[id]/[userId]',
    '/property/[id]',
    '/acm/[id]',
    '/register',
    '/register/[id]',
    '/visit/[id]',
    '/valuation/[id]'
];

export { routes, publicRoutes };

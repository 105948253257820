import React, { SVGProps } from 'react';

const UpFloorSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M67.065 2.08105H50.9125V18.2335H34.5325V34.386H18.38V50.766H2V66.9186"
            stroke="black"
            strokeWidth="3.2355"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.3733 34.8413L34.9883 55.9988"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.6328 34.1587H57.0553L57.2828 47.5812"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UpFloorSvg;

import nookies from 'nookies';
import { axios } from '../utils/axios';
import Axios from 'axios';

export const instance = Axios.create({
    // withCredentials: true
});

const fetcher = async <T = any>(url: string): Promise<any> => {
    const res = await axios.get<T>(url);
    return res.data;
};
let token = '';

export const GetToken = () => token;

export const setRadarToken = (token) => {
    delete axios.defaults.headers.common['mi-pulppo-access-token'];
    delete instance.defaults.headers.common['mi-pulppo-access-token'];
    nookies.destroy(undefined, 'radarToken');
    axios.defaults.headers.common['mi-pulppo-access-token'] = `${token}`;
    instance.defaults.headers.common['mi-pulppo-access-token'] = `${token}`;

    nookies.set(undefined, 'radarToken', token, {
        path: '/',
        sameSite: 'None',
        secure: true,
        expires: new Date(2147483647000)
    });
};

const setToken = (newToken) => {
    token = newToken;
    if (!newToken) {
        delete axios.defaults.headers.common['pulppo-access-token'];
        delete instance.defaults.headers.common['pulppo-access-token'];
        nookies.destroy(undefined, 'token');
        return;
    }
    axios.defaults.headers.common['pulppo-access-token'] = `${token}`;
    instance.defaults.headers.common['pulppo-access-token'] = `${token}`;
    localStorage.setItem('token', token);

    nookies.set(undefined, 'token', token, {
        path: '/',
        sameSite: 'None',
        secure: true,
        expires: new Date(2147483647000)
    });
};

const setNewToken = (newToken) => {
    token = newToken;
    delete axios.defaults.headers.common['pulppo-access-token'];
    delete instance.defaults.headers.common['pulppo-access-token'];
    nookies.destroy(undefined, 'token');
    nookies.destroy(undefined, 'new-token');
    delete axios.defaults.headers.common['authorization'];
    delete instance.defaults.headers.common['authorization'];
    if (!newToken) {
        return;
    }
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    instance.defaults.headers.common['authorization'] = `Bearer ${token}`;

    nookies.set(undefined, 'new-token', token, {
        path: '/',
        sameSite: 'None',
        secure: true,
        expires: new Date(2147483647000)
    });
};
const cookies = nookies.get(undefined);
const oldToken = cookies['token'];
const oldNewToken = cookies['new-token'];
const radarToken = cookies['radarToken'];

if (oldToken && !oldNewToken) setToken(oldToken);
if (oldNewToken) setNewToken(oldNewToken);
if (radarToken) setRadarToken(radarToken);
const api_url = process.env.NEXT_PUBLIC_API_URL;
const api_legal_url =
    process.env.NEXT_PUBLIC_API_LEGAL_URL ||
    (process.env.NODE_ENV === 'development' ? 'https://api.dev.pulppo.com' : process.env.NEXT_PUBLIC_API_URL);
const legal_url =
    process.env.NEXT_PUBLIC_LEGAL_URL ||
    (process.env.NODE_ENV === 'development'
        ? 'https://broker.dev.pulppo.com/legal-tool'
        : 'https://broker.pulppo.com/legal-tool');
const wapp_url = process.env.NEXT_PUBLIC_WAPP_URL;

export { fetcher, api_url, wapp_url, api_legal_url, setToken, legal_url, setNewToken };

import { useContext, useState } from 'react';
import { MapUserToSimpleUser } from '../../utils/MapContactToSimpleUser';
import { UserContext } from '../../contexts/UserContext';
import { Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';
import Button from './Button';

export const Comment = ({ onSend }) => {
    const { user } = useContext(UserContext);
    const [error, setError] = useState('');
    const [comment, setComment] = useState({
        user: MapUserToSimpleUser(user),
        content: '',
        createdAt: new Date(),
        timestamp: new Date()
    });
    const onFinish = async () => {
        if (!comment.content) {
            return setError('Tiene que insertar contenido');
        }
        onSend(comment);
        setComment({} as any);
    };
    return (
        <div>
            <div className="flex">
                <Input
                    value={comment.content}
                    onPressEnter={onFinish}
                    onChange={(val) => {
                        setError('');
                        setComment((comment) => ({
                            ...comment,
                            createdAt: new Date(),
                            timestamp: new Date(),
                            user: MapUserToSimpleUser(user),
                            content: val.target?.value
                        }));
                    }}
                    className={twMerge(
                        'flex-1 rounded-none p-2 shadow-none transition-all',
                        error && 'border border-solid border-red-500'
                    )}
                    placeholder="Agregar comentario..."
                />

                <Button
                    type="secondary"
                    onClick={onFinish}
                    className="h-auto border-y border-l-0 border-r border-solid border-gray-300 ring-0"
                    icon={<SendOutlined className="text-pulppo-status-dark-disabled" />}
                />
            </div>
            {error && <p className="text-sm text-red-500">{error}</p>}
        </div>
    );
};

import { useRouter } from 'next/router';
import Script from 'next/script';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { publicRoutes } from '../Sidebar/routes';

const Scripts = () => {
    const router = useRouter();
    const { user } = useContext(UserContext);
    return (
        <>
            {user && !publicRoutes.includes(router.pathname) && user?.company?._id !== '6318187c85c9c01ba7b3dd57' && (
                <>
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe
              src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-NSXL6FB'}"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>`
                        }}
                    />
                    <Script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-NSXL6FB'}');`}</Script>
                </>
            )}

            {/*Facebook Marketing Center*/}
            <Script
                id="facebook"
                dangerouslySetInnerHTML={{
                    __html: `window.fbAsyncInit = function() {
            FB.init({
                appId: '${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}',
                cookie: true,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v14.0'
            });
        };`
                }}
            />
            <Script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
            <Script
                strategy="beforeInteractive"
                crossOrigin="anonymous"
                src="https://cdn.jsdelivr.net/npm/container-query-polyfill@1/dist/container-query-polyfill.modern.js"
            />
        </>
    );
};

export default Scripts;

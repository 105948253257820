import React, { SVGProps } from 'react';

const BathroomSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M66.61 49.401C66.61 53.041 63.6525 55.9985 60.0125 55.9985H14.0575H8.59749C4.95749 55.9985 2 53.041 2 49.401V33.021H66.3825V49.401H66.61Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.1001 55.9985L8.14258 67.146"
            stroke="black"
            strokeWidth="3.27008"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.373 55.9985L59.5581 67.146"
            stroke="black"
            strokeWidth="3.27008"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.3736 33.021V8.45104C56.3736 4.81104 53.4161 1.85352 49.7761 1.85352H37.0361V14.366"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.3045 15.731H39.992C42.4945 15.731 44.7695 17.096 45.907 19.5985L47.0445 22.101H27.252L28.3895 19.5985C29.527 17.096 31.802 15.731 34.3045 15.731Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default BathroomSvg;

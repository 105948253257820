import { IClose, IOperation, IProperty } from '@pulppo/pulppo-models';

export enum ColorBadge {
    Yellow,
    Green,
    Gray,
    Red,
    Blue,
    Black
}

export const getCloseStatusColor = (status: IClose['status']): ColorBadge => {
    return status === 'pending' ? ColorBadge.Gray : status === 'expired' ? ColorBadge.Yellow : ColorBadge.Green;
};

export const getOperationStatusColor = (status: IOperation['status']['last']): ColorBadge => {
    return status === 'offer' || status === 'offer_blocked' || status === 'contract'
        ? ColorBadge.Yellow
        : status === 'paying' || status === 'closed'
          ? ColorBadge.Green
          : status === 'cancelled'
            ? ColorBadge.Red
            : ColorBadge.Gray;
};

export const getLegalStatusColor = (status: IProperty['contract']['status']): ColorBadge => {
    return status === 'pending'
        ? ColorBadge.Gray
        : status === 'revision' || status === 'contract_sent'
          ? ColorBadge.Yellow
          : status === 'completed' || status === 'approved'
            ? ColorBadge.Green
            : status === 'rejected'
              ? ColorBadge.Red
              : ColorBadge.Gray;
};

export const COLORS = {
    primary: {
        yellow: '#EAB713',
        gray: '#838C8C',
        'tech-blue': '#1C24B7',
        blue: '#1890FF',
        black: '#212322'
    },
    secondary: {
        bg: '#FDFDFD',
        gray: {
            disabled: '#D9D9D9',
            line: '#E4E4E4'
        }
    },
    status: {
        dark: {
            success: '#1D7447',
            error: '#C12300',
            warning: '#F4B910',
            info: '#2A98FA',
            disabled: '#535656'
        },
        light: {
            success: '#E8FFEA',
            error: '#FDF1F0',
            warning: '#FEF4DD',
            info: '#DEEFFF',
            disabled: '#F4F7FD'
        }
    },
    details: {
        green: '#428F4C',
        'light-green': '#E7F9E3',
        medium: '#FF842B',
        aqua: '#6BBACE',
        yellow: '#FFF8CB'
    },
    progress: {
        green: '#C9ED3B',
        orange: '#FDC785',
        red: '#FF7B74'
    },
    chart: {
        teal: '#14B8A6',
        amber: '#FACC15',
        orange: '#F59E0B',
        blue: '#3B82F6',
        indigo: '#6366F1'
    }
};

import React, { SVGProps } from 'react';
const CompassSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        {...props}
    >
        <path
            d="M34.125 3.7875C50.96 3.7875 64.8375 17.665 64.8375 34.5C64.8375 51.335 50.96 65.2125 34.125 65.2125C17.29 65.2125 3.4125 51.335 3.4125 34.5C3.4125 17.665 17.0625 3.7875 34.125 3.7875ZM34.125 0.375C15.2425 0.375 0 15.6175 0 34.5C0 53.3825 15.2425 68.625 34.125 68.625C53.0075 68.625 68.25 53.3825 68.25 34.5C68.25 15.6175 53.0075 0.375 34.125 0.375Z"
            fill="black"
        />
        <path
            d="M34.1253 44.2823C39.528 44.2823 43.9078 39.9025 43.9078 34.4998C43.9078 29.097 39.528 24.7173 34.1253 24.7173C28.7225 24.7173 24.3428 29.097 24.3428 34.4998C24.3428 39.9025 28.7225 44.2823 34.1253 44.2823Z"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M30.4847 43.3723L15.4697 53.1549L24.3422 39.2773"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M37.7637 25.4002L52.7787 15.8452L42.9962 30.8602"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default CompassSvg;

import BathroomSvg from '../Svg/BathroomSvg';
import BedSvg from '../Svg/BedSvg';
import DownFloorSvg from '../Svg/DownFloorSvg';
import UpFloorSvg from '../Svg/UpFloorSvg';
import MeasureSvg from '../Svg/MeasureSvg';
import MtsSvg from '../Svg/MtsSvg';
import HouseRoofed from '../Svg/HouseRoofedSvg';
import GarageSvg from '../Svg/GarageSvg';
import ArrowBottomLeftOnSquareSvg from '../Svg/ArrowBottomLeftOnSquareSvg';
import ArrowTopRightOnSquareSvg from '../Svg/ArrowTopRightOnSquareSvg';
import CompassSvg from '../Svg/CompassSvg';
import HourglassSvg from '../Svg/HourglassSvg';
import TreeSvg from '../Svg/TreeSvg';
import ToiletSvg from '../Svg/ToiletSvg';
import PriceSvg from '../Svg/PriceSvg';
import { SVGProps } from 'react';
import SortSvg from '../Svg/SortSvg';
import ClapSvg from '../Svg/ClapSvg';

export enum IconType {
    'Bed' = 'bed',
    'Clap' = 'clap',
    'Bathroom' = 'bathroom',
    'DownFloor' = 'downFloor',
    'UpFloor' = 'upFloor',
    'Measure' = 'measure',
    'Mts' = 'mts',
    'Upgrade' = 'upgrade',
    'Garage' = 'garage',
    'ArrowBottomLeftOnSquare' = 'arrowBottomLeftOnSquare',
    'ArrowTopRightOnSquare' = 'arrowTopRightOnSquare',
    'Compass' = 'compass',
    'Hourglass' = 'hourglass',
    'Price' = 'price',
    'Tree' = 'tree',
    'Toilet' = 'toilet',
    'Sort' = 'sort'
}

interface IconProps extends SVGProps<SVGSVGElement> {
    name: IconType;
}

const Icon = ({ name, ...props }: IconProps) => {
    switch (name) {
        case IconType.Bed:
            return <BedSvg {...props} />;
        case IconType.Bathroom:
            return <BathroomSvg {...props} />;
        case IconType.Clap:
            return <ClapSvg {...props} />;
        case IconType.DownFloor:
            return <DownFloorSvg {...props} />;
        case IconType.UpFloor:
            return <UpFloorSvg {...props} />;
        case IconType.Measure:
            return <MeasureSvg {...props} />;
        case IconType.Mts:
            return <MtsSvg {...props} />;
        case IconType.Upgrade:
            return <HouseRoofed {...props} />;
        case IconType.Garage:
            return <GarageSvg {...props} />;
        case IconType.ArrowBottomLeftOnSquare:
            return <ArrowBottomLeftOnSquareSvg {...props} />;
        case IconType.ArrowTopRightOnSquare:
            return <ArrowTopRightOnSquareSvg {...props} />;
        case IconType.Compass:
            return <CompassSvg {...props} />;
        case IconType.Hourglass:
            return <HourglassSvg {...props} />;
        case IconType.Price:
            return <PriceSvg {...props} />;
        case IconType.Toilet:
            return <ToiletSvg {...props} />;
        case IconType.Tree:
            return <TreeSvg {...props} />;
        case IconType.Sort:
            return <SortSvg {...props} />;

        default:
            return <BedSvg {...props} />;
    }
};

export default Icon;

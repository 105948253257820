import React, { SVGProps } from 'react';

const DownFloorSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="69"
        viewBox="0 0 73 69"
        fill="none"
        {...props}
    >
        <path
            d="M66.6086 22.4424V48.6049C66.6086 50.6524 64.7886 52.4724 62.7411 52.4724H20.8811C18.8336 52.4724 17.0137 50.6524 17.0137 48.6049V23.3524"
            stroke="black"
            strokeWidth="3.33674"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.7871 31.0874H44.5421C46.5896 31.0874 48.4096 29.2674 48.4096 27.2199V6.74493C48.4096 4.69743 46.5896 2.87744 44.5421 2.87744H20.6546C18.6071 2.87744 16.7871 4.69743 16.7871 6.74493V31.0874Z"
            stroke="black"
            strokeWidth="3.33674"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.63867 1.96777V53.8378"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.332 63.6196H66.8371"
            stroke="black"
            strokeWidth="3.33629"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.27996 4.46974L5.64001 1.73975L2 4.46974"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.1539 60.207L15.4238 63.6195L18.1539 67.2595"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M64.1074 67.2595L66.8374 63.6195L64.1074 60.207"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 51.1069L5.64001 53.8369L9.27996 51.1069"
            stroke="black"
            strokeWidth="3.4125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.7871 15.1621H48.1821"
            stroke="black"
            strokeWidth="3.33674"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="4.45 4.45"
        />
        <path
            d="M41.5859 52.4721V32.2246"
            stroke="black"
            strokeWidth="3.33674"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="4.45 4.45"
        />
        <path
            d="M49.5488 21.3052H70.4789"
            stroke="black"
            strokeWidth="3.33674"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="4.89 4.89"
        />
    </svg>
);
export default DownFloorSvg;

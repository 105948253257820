import 'antd/dist/antd.css';
import '../styles/tailwind.css';
import '../styles/globals.scss';
import 'react-phone-number-input/style.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';

import { SWRConfig } from 'swr';
import { ConfigProvider, Layout } from 'antd';
import dynamic from 'next/dynamic';
import { ComponentType, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import '../services/authentication';
import { UserContextProvider } from '../contexts/UserContext';
import dayjs from 'dayjs';
import esES from 'antd/lib/locale/es_ES';
import Scripts from '../components/Scripts/Scripts';
import HeadGlobal from '../components/Head/HeadGlobal';
import { publicRoutes } from '../components/Sidebar/routes';
import { twMerge } from 'tailwind-merge';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/es-mx';

const LoyaltyDrawer: ComponentType<any> = dynamic(
    () => import('../components/Loyalty/LoyaltyDrawer').then((r) => r.LoyaltyDrawer),
    {
        ssr: false
    }
);

const PendingLeads: ComponentType<any> = dynamic(
    () => import('../components/PendingLeads').then((r) => r.PendingLeads),
    {
        ssr: false
    }
);

const OnlineCheck: ComponentType<any> = dynamic(() => import('../components/OnlineCheck').then((r) => r.OnlineCheck), {
    ssr: false
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault('America/Mexico_City');
dayjs.locale('es-MX');

import '../utils/dateConfig';
import { TolgeeNextProvider } from '../contexts/TolgeeProvider';
import { MobileRightSidebar } from '../components/Sidebar/MobileRightSidebar';
import useUser from '../hooks/useUser';
import { PulppoNewLoader } from '../components/Elements/PulppoLoader';
import { api_url, instance } from '../helpers/fetcher';
import useCompany from '../hooks/useCompany';

const clearCache = async () => {
    console.log(`Clearing Cache`);
    const cacheNames = await caches.keys();
    console.log(`Deleting ${cacheNames.length} caches`);
    for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
    }
};

if (typeof localStorage !== 'undefined') {
    const setItem = localStorage.setItem;
    localStorage.setItem = function (key: string, value: string) {
        try {
            setItem.call(this, key, value);
        } catch (err) {
            console.error(err);
            console.log(`Found quota exceeded error`);
            clearCache();
        }
    };
}

const LeftSidebar: ComponentType<any> = dynamic(() => import('../components/Sidebar/LeftSidebar'), {
    ssr: false
});

const CallCenterWindow: ComponentType<any> = dynamic(() => import('../components/CallCenter/CallCenterWindow'), {
    ssr: false
});

const FireChat: ComponentType<any> = dynamic(() => import('../components/FireChat').then((r) => r.FireChat), {
    ssr: false
});

const PrecontactList: ComponentType<any> = dynamic(
    () => import('../components/Contact/PrecontactList').then((r) => r.PrecontactList),
    {
        ssr: false
    }
);

const RightSidebar: ComponentType<any> = dynamic(() => import('../components/Sidebar/RightSidebar'), {
    ssr: false
});

function MyApp({ Component, pageProps }) {
    const [loading, setLoading] = useState(false);
    const [showContacts, setShowContacts] = useState(
        typeof localStorage != 'undefined' &&
            (!localStorage.getItem('showContactListAfter') ||
                dayjs()
                    .tz()
                    .isAfter(dayjs(localStorage.getItem('showContactListAfter')).tz()))
    );
    const router = useRouter();
    const user = useUser((u) => u.user);
    const setCompany = useCompany((c) => c.setCompany);

    useEffect(() => {
        let user = null;
        try {
            const userJSON = localStorage.getItem('user');
            user = JSON.parse(userJSON);
        } catch {}
        if (user) {
            (window as any).intercomSettings = {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'tosduqgv',
                user_id: user._id,
                alignment: 'left',
                avatar: {
                    type: 'avatar',
                    image_url: user.profilePicture
                },
                company: {
                    company_id: user.company?._id,
                    name: user?.company?.name,
                    country: process.env.NEXT_PUBLIC_COUNTRY || 'México'
                },
                firstName: user.firstName,
                lastName: user.lastName,
                country: process.env.NEXT_PUBLIC_COUNTRY || 'México',
                name: `${user.firstName} ${user.lastName}`, // Full name
                email: user.email, // Email address
                created_at: new Date(user.createdAt).getTime() // Signup date as a Unix timestamp
            };
            (function () {
                const w = window as any;
                const ic = w.Intercom as any;
                if (typeof ic === 'function') {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    const d = document;
                    const i: any = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    const l = function () {
                        const s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/tosduqgv';
                        const x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (document.readyState === 'complete') {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            })();
        }
        const handleStart = (url: string) => {
            url !== router.pathname ? setLoading(true) : setLoading(false);
        };
        const handleComplete = () => {
            (window as any)?.Intercom?.('update');
            const showAfter = localStorage.getItem('showContactListAfter');
            const showAfterDate = showAfter ? dayjs(showAfter).tz() : null;
            if (!showAfterDate || dayjs().tz().isAfter(showAfterDate)) setShowContacts(true);
            setLoading(false);
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);
        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, [router]);

    useEffect(() => {
        if (user?.company?._id) {
            instance.get(`${api_url}/company/${user.company._id}`).then((res) => {
                setCompany(res.data);
            });
        }
    }, [user?.company?._id]);

    return (
        <>
            <TolgeeNextProvider>
                <HeadGlobal />
                <ConfigProvider locale={esES}>
                    <SWRConfig
                        value={{
                            revalidateOnFocus: false,
                            revalidateOnReconnect: false
                        }}
                    >
                        <UserContextProvider>
                            <Scripts />
                            <PrecontactList show={showContacts} setShow={setShowContacts} />
                            <PendingLeads />
                            <Layout className="flex h-full w-full flex-col overflow-x-hidden md:flex-row print:h-auto print:bg-white">
                                <LeftSidebar />
                                <PulppoNewLoader className="h-full flex-1 overflow-y-auto" loading={loading}>
                                    <div
                                        className={twMerge(
                                            'h-full print:px-0',
                                            !publicRoutes.includes(router.pathname) && 'md:pl-16 md:pr-14'
                                        )}
                                    >
                                        <Component {...pageProps} />
                                    </div>
                                </PulppoNewLoader>
                                <RightSidebar />
                                <MobileRightSidebar />
                                <CallCenterWindow />
                            </Layout>
                            <OnlineCheck />
                            <FireChat />
                            <LoyaltyDrawer />
                            <div id="agent-modal" />
                        </UserContextProvider>
                    </SWRConfig>
                </ConfigProvider>
            </TolgeeNextProvider>
        </>
    );
}

export default MyApp;

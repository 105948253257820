import { Button, ButtonProps, notification } from 'antd';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const LoadingButton = (props: ButtonProps) => {
    const [loading, setLoading] = useState(false);
    return (
        <Button
            {...props}
            className={twMerge('rounded-none', props?.className)}
            loading={loading}
            disabled={props?.disabled || loading}
            onClick={(ev) => {
                if (loading) return;
                setLoading(true);

                (props.onClick?.(ev) as any)
                    ?.catch?.((err) => {
                        console.error(err?.response);
                        notification.error({
                            message: `Error: ${err?.response?.data?.error || err?.response?.data?.message || err?.message || err}`
                        });
                    })
                    ?.finally?.(() => setLoading(false));
            }}
        />
    );
};

const MenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clipPath="url(#clip0_800_609)">
            <g opacity="0.08">
                <path
                    d="M4.92218 0.649963H12.8565C15.0061 0.649963 16.749 2.39284 16.749 4.54246V12.4768C16.749 14.6264 15.0061 16.3693 12.8565 16.3693H4.92218C2.77255 16.3693 1.02968 14.6264 1.02968 12.4768V4.54246C1.02968 2.39287 2.77255 0.649963 4.92218 0.649963Z"
                    fill="#212322"
                />
            </g>
            <path
                d="M4.92218 0.369263H12.8565C15.0061 0.369263 16.749 2.11214 16.749 4.26176V12.1961C16.749 14.3457 15.0061 16.0886 12.8565 16.0886H4.92218C2.77255 16.0886 1.02968 14.3457 1.02968 12.1961V4.26176C1.02968 2.11214 2.77255 0.369263 4.92218 0.369263Z"
                fill="#F4F7FD"
            />
            <g opacity="0.16">
                <path
                    d="M12.8565 0.369263H4.92218C2.59402 0.369263 1.02968 1.93361 1.02968 4.26176V12.1961C1.02968 14.5242 2.59402 16.0886 4.92218 16.0886H12.8565C15.1846 16.0886 16.749 14.5242 16.749 12.1961V4.26176C16.749 1.93361 15.1846 0.369263 12.8565 0.369263ZM16.1876 12.1961C16.1876 14.2197 14.8801 15.5272 12.8565 15.5272H4.92218C2.89858 15.5272 1.59108 14.2197 1.59108 12.1961V4.26176C1.59108 2.23817 2.89858 0.930669 4.92218 0.930669H12.8565C14.8801 0.930669 16.1876 2.23817 16.1876 4.26176V12.1961Z"
                    fill="white"
                />
            </g>
            <g opacity="0.16">
                <path
                    d="M12.8565 0.369263H4.92218C2.59402 0.369263 1.02968 1.93361 1.02968 4.26176V12.1961C1.02968 14.5242 2.59402 16.0886 4.92218 16.0886H12.8565C15.1846 16.0886 16.749 14.5242 16.749 12.1961V4.26176C16.749 1.93361 15.1846 0.369263 12.8565 0.369263ZM16.4683 12.1961C16.4683 14.3564 15.0168 15.8079 12.8565 15.8079H4.92218C2.7619 15.8079 1.3104 14.3564 1.3104 12.1961V4.26176C1.3104 2.10148 2.7619 0.649981 4.92218 0.649981H12.8565C15.0168 0.649981 16.4683 2.10148 16.4683 4.26176V12.1961Z"
                    fill="#212322"
                />
            </g>
            <circle cx="8.88933" cy="4.91956" r="0.872803" fill="#999999" />
            <circle cx="8.88933" cy="8.36926" r="0.872803" fill="#999999" />
            <circle cx="8.88933" cy="11.819" r="0.872803" fill="#999999" />
        </g>
        <defs>
            <clipPath id="clip0_800_609">
                <rect width="16" height="16" fill="white" transform="translate(0.889328 0.369263)" />
            </clipPath>
        </defs>
    </svg>
);
export default MenuIcon;

import Head from 'next/head';
import { useRouter } from 'next/router';
import { routes } from '../Sidebar/routes';
import { useTranslate } from '@tolgee/react';

const HeadGlobal = () => {
    const router = useRouter();
    const { t } = useTranslate('left_menu');
    const found = routes.find((route) => router.pathname === route.route)?.title;
    return (
        <Head>
            <title>
                {`${process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'} - ${
                    routes &&
                    (found
                        ? t(found)
                        : router.asPath
                              .split('/')
                              .slice(0, 2)
                              .filter(Boolean)
                              // .filter((elem, i) => elem && i < 2)
                              .map((elem) => t(`${elem.charAt(0).toUpperCase()}${elem.slice(1)?.split('?')[0]}`))
                              .join(' - '))
                }`}
            </title>
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
        </Head>
    );
};

export default HeadGlobal;

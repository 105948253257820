import React, { SVGProps } from 'react';

const HouseRoofed = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="67"
        viewBox="0 0 69 67"
        fill="none"
        {...props}
    >
        <path
            stroke="#000"
            strokeWidth={0.1}
            d="M426.67,469.33h-128A21.33,21.33,0,0,1,277.33,448V362.67H234.67V448a21.33,21.33,0,0,1-21.34,21.33h-128A21.33,21.33,0,0,1,64,448V234.67H42.67a21.33,21.33,0,0,1-12.24-38.81L243.77,46.52a21.33,21.33,0,0,1,24.46,0L481.57,195.86a21.33,21.33,0,0,1-12.24,38.81H448V448A21.33,21.33,0,0,1,426.67,469.33ZM320,426.67h85.33V213.33a21.25,21.25,0,0,1,5.85-14.67L256,90,100.82,198.66a21.25,21.25,0,0,1,5.85,14.67V426.67H192V341.33A21.33,21.33,0,0,1,213.33,320h85.34A21.33,21.33,0,0,1,320,341.33Z"
        />
    </svg>
);
export default HouseRoofed;

import { create } from 'zustand';
import { ICompany } from '@pulppo/pulppo-models';

interface UseCompanyStore {
    company?: ICompany;
    setCompany: (company: ICompany) => ICompany;
}

export const useCompany = create<UseCompanyStore>((set) => ({
    company: null,
    setCompany: (company) => {
        set({ company });
        return company;
    }
}));

export default useCompany;

import React, { SVGProps } from 'react';

const TreeSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="71"
        viewBox="0 0 72 71"
        fill="none"
        {...props}
    >
        <path
            d="M35.6705 2.28516L21.1105 27.7652H29.983L16.1055 52.1076H35.6705"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M35.6699 2.28516L50.9125 27.7652H42.2674L55.9174 52.1076H36.3525"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M35.6699 30.7227V67.3502"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M70.25 68.7148H2"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M41.3584 37.3203H35.8984"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M35.6709 41.6426H30.2109"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M41.3584 47.1025H35.8984"
            stroke="black"
            strokeWidth="2.77459"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default TreeSvg;
